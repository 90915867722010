import { NavLink } from '@remix-run/react';

import clsx from 'clsx';

type NavTabsProps = {
  className?: string;
  children: React.ReactNode;
};
export const NavTabs = ({ className, children }: NavTabsProps) => (
  <div className={clsx('flex gap-2 bg-neutral-50 px-4 py-3', className)}>
    {children}
  </div>
);

const sharedTabChipStyle = (isActive: boolean, disabled: boolean) =>
  clsx(
    'flex items-center justify-center rounded-full border border-transparent px-3 py-1.5 text-center text-sm font-medium leading-normal transition-all hover:text-neutral-600',
    {
      'bg-transparent text-neutral-500': !isActive,
      '!border-neutral-250 bg-neutral-0 text-neutral-600 shadow-md': isActive,
      'pointer-events-none !cursor-not-allowed': disabled,
    },
  );

type NavTabProps = {
  label: string;
  to: string;
  className?: string;
  disabled?: boolean;
};
export const NavTab = ({ label, to, className, disabled }: NavTabProps) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      clsx(sharedTabChipStyle(isActive, !!disabled), className)
    }
  >
    {label}
  </NavLink>
);

type ChipTabProps = {
  label: string;
  className?: string;
  disabled?: boolean;
  isActive: boolean;
  onClick: () => void;
};

export const ChipTab = ({
  label,
  className,
  disabled = false,
  isActive,
  onClick,
}: ChipTabProps) => (
  <button
    className={clsx(sharedTabChipStyle(isActive, disabled), className)}
    onClick={onClick}
  >
    {label}
  </button>
);
